import {
    Button,
    Grid,
    Typography
} from "@mui/material";
import {
    useEffect,
    useRef,
    useState
}                                from "react";
import {
    AddComplianceSchedule,
    ComplianceSchedule,
    ComplianceScheduleDetail,
    DeactivateComplianceScheduleCommand,
    Delete,
    IGStyle,
    UpdateComplianceSchedule,
} from "../../constants";
import {useZINContext} from "../../context/ZinContextProvider";
import {submitObject}            from "../../util";

const HeffingsTariefBlock = () =>
{
    const {
        companyDetails,
        setFormData,
        setDeleteCallback,
        setModalVisible,
        setModalAction,
        refreshResource, setRefreshResource,
        setAlertVisible,
        setAlertText,
    } = useZINContext()

    //<editor-fold desc="Changeable Variables">
    const AggregateDetails = companyDetails

    const Title = "Compliance Schedule"
    const RMName = "ComplianceSchedule_ZINRM"
    const RDName = "ComplianceSchedule_ZINRD"

    const DetailRefresh = ComplianceScheduleDetail
    const MasterRefresh = ComplianceSchedule

    const AddAction = AddComplianceSchedule
    const UpdateAction = UpdateComplianceSchedule

    const AddButton = "Compliance Schedule Toevoegen"
    const AddEnabled = true

    const UpdateButton = "Compliance Schedule Bewerken"
    const UpdateEnabled = true

    const DeleteButton = "Compliance Schedule Verwijderen"
    const DeleteEnabled = false
    const DeleteCommand = DeactivateComplianceScheduleCommand

    const DSLFilter = ""
    //</editor-fold>

    //<editor-fold desc="DetailMaster - Does not need changing">

    const RMRef = useRef<any>()
    const RDRef = useRef<any>()

    const [detailStatus, setDetailStatus] = useState<any>(false)
    const [detailRecord, setDetailRecord] = useState<any>("")

    useEffect(() =>
    {
        RMRef.current?.addEventListener('recordSelected', (event: any) =>
        {
            setFormData(event.detail.record)
            setDetailStatus(true)
            setDetailRecord(event.detail.recordId)
        })
    }, [])

    useEffect(() =>
    {
        switch (refreshResource)
        {
            case MasterRefresh:
                setTimeout(() => RMRef.current?.refresh(), 1000)
                break
            case DetailRefresh:
                setTimeout(() => RDRef.current?.refresh(), 1000)
                break
        }
        setRefreshResource("")
    }, [refreshResource]);

    function ResetDetailStatus()
    {
        setRefreshResource(MasterRefresh)
        setDetailStatus(false)
    }

    function AddObject()
    {
        setFormData(RMRef.current?.data)

        setModalVisible(true)
        setModalAction(AddAction)
    }

    function UpdateObject()
    {
        setFormData(RDRef.current?.data)

        setModalVisible(true)
        setModalAction(UpdateAction)
    }

    function DeleteObject()
    {
        submitObject({id: RDRef.current?.data.id}, AggregateDetails?.id, DeleteCommand).then(async (event: any) =>
        {
            HandleSubmit(event)
        })

        setDetailStatus(false)
        setRefreshResource(MasterRefresh)
    }

    async function HandleSubmit(event: any)
    {
        //In case of a status that isnt 200, we most likely have received an error message.
        //So we display this as alert in the Modal
        if (event.status !== 200)
        {
            const response = await event.json()
            setAlertVisible(true)
            setAlertText(response.messages.reduceRight((acum: any, current: any) => acum.concat(current)))
        }
    }

    const handleStartDelete = (event: React.MouseEvent<HTMLButtonElement>) =>
    {
        setModalVisible(true)
        setModalAction(Delete)
        setDeleteCallback({delete: DeleteObject})
    };

    return (<>
        <Grid container justifyContent="center" direction={"column"}>
            <Grid container className={"rm-container"} direction={"row"}>
                <Grid container>
                    <Typography variant="h4" marginRight={"auto"}>
                        {Title}
                    </Typography>
                </Grid>

                <Grid item xs={8} container alignItems={"stretch"} className={"containerspacing"}>
                    <div style={{
                        display: detailStatus
                                 ? "contents"
                                 : "none"
                    }}>
                        <Button onClick={ResetDetailStatus}>Back</Button>
                        {/*// @ts-ignore*/}
                        <ig-resource-detail lang="nl-NL" ref={RDRef} class={"ig-detail-remove-1st-row"} style={IGStyle} resource-detail-id={RDName} resource-id={detailRecord}/>
                    </div>

                    <div style={{
                        display: detailStatus
                                 ? "none"
                                 : "contents"
                    }}> {/*// @ts-ignore*/}
                        <ig-resource-master lang="nl-NL" ref={RMRef} style={IGStyle} class={"ig-hide-1st-column"} resource-master-id={RMName} dsl-filter={DSLFilter}/>
                    </div>

                </Grid>

                <Grid container item className={"button-container"} xs={3} marginLeft={1} justifyContent={"flex-start"}>

                    <Grid container item xs={4}>
                        <Grid container direction="column" item>
                            {AddEnabled && <Button variant={"contained"} disabled={detailStatus} onClick={AddObject}>{AddButton}</Button>}
                            {UpdateEnabled && <Button variant={"contained"} disabled={!detailStatus} onClick={UpdateObject}>{UpdateButton}</Button>}
                            {DeleteEnabled && <Button variant={"contained"} disabled={!detailStatus} onClick={handleStartDelete}>{DeleteButton}</Button>}
                        </Grid>
                    </Grid>

                </Grid>

            </Grid>
        </Grid>
    </>)
//</editor-fold>
}

export default HeffingsTariefBlock
