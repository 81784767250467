import {
    Button,
    Grid,
    Typography
}                      from "@mui/material";
import {
    useEffect,
    useRef,
    useState
} from "react";
import {
    AddBassin,
    AddContact,
    AddInstallatie,
    Aerosol,
    Contact,
    ContactDetail,
    DeactivateContactCommand,
    DeactivateInstallatieCommand,
    IGStyle,
    Installatie,
    InstallatieDetail,
    UpdateBassin,
    UpdateInstallatie,
    UpdatePool
} from "../../constants";
import {useZINContext} from "../../context/ZinContextProvider";
import {submitObject} from "../../util";

const InstallatiesBlock = () =>
{
    const {
        poolDetails,
        poolDSLFilter,
        setFormData,
        setModalVisible,
        setModalAction, setRefreshResource, refreshResource, setAlertVisible, setAlertText
    } = useZINContext()

    const RMRef = useRef<any>()
    const RDRef = useRef<any>()
    const [detailStatus, setDetailStatus] = useState<any>(false)
    const [detailRecord, setDetailRecord] = useState<any>("")

    useEffect(() =>
    {
        RMRef.current?.addEventListener('recordSelected', (event: any) =>
        {
            setFormData(event.detail.record)
            setDetailStatus(true)
            setDetailRecord(event.detail.recordId)
        })

    }, [])

    useEffect(() =>
    {
        switch (refreshResource)
        {
            case Installatie:
                setTimeout(() => RMRef.current?.refresh(), 1000)
                break
            case InstallatieDetail:
                setTimeout(() => RDRef.current?.refresh(), 1000)
                break
        }
        setRefreshResource("")
    }, [refreshResource]);

    function ResetDetailStatus()
    {
        setDetailStatus(false)
        setRefreshResource(Installatie)
    }

    function InstallatiesToevoegen()
    {
        setFormData(undefined)

        setModalVisible(true)
        setModalAction(AddInstallatie)
    }
    function InstallatiesBewerken()
    {
        setFormData(RDRef.current?.data)

        setModalVisible(true)
        setModalAction(UpdateInstallatie)
    }

    function InstallatiesVerwijderen()
    {
        submitObject({id: RDRef.current?.data.id},  poolDetails?.id, DeactivateInstallatieCommand).then(async (event: any) =>
        {
            HandleSubmit(event)
        })

        setDetailStatus(false)
        setRefreshResource(Installatie)
    }
    async function HandleSubmit(event: any)
    {
        //In case of a status that isnt 200, we most likely have received an error message.
        //So we display this as alert in the Modal
        if (event.status !== 200){
            const response = await event.json()
            setAlertVisible(true)
            setAlertText(response.messages.reduceRight((acum: any, current: any) => acum.concat(current)))
        }
    }

    return (<>
        <Grid container justifyContent="center" direction={"column"}>
            <Grid container className={"rm-container"} direction={"row"}>
                <Grid container>
                    <Typography variant="h4" marginRight={"auto"}>
                        Installaties
                    </Typography>
                </Grid>

                <Grid item xs={8} container alignItems={"stretch"} className={"containerspacing"}>

                    <div style={{
                        display: detailStatus
                                 ? "contents"
                                 : "none"
                    }}>
                        <Button onClick={ResetDetailStatus}>Back</Button>
                        {/*// @ts-ignore*/}
                        <ig-resource-detail lang="nl-NL" ref={RDRef} class={"ig-detail-remove-2rows"} style={IGStyle} resource-detail-id="SystemRD" resource-id={detailRecord}/>
                    </div>
                    <div style={{
                        display: detailStatus
                                 ? "none"
                                 : "contents"
                    }}>                     {/*// @ts-ignore*/}
                        <ig-resource-master lang="nl-NL" ref={RMRef} style={IGStyle} class={"ig-columns"} resource-master-id="SystemRM" dsl-filter={poolDSLFilter}/>
                    </div>

                </Grid>

                <Grid container item className={"button-container"} xs={3} marginLeft={1} justifyContent={"flex-start"}>

                <Grid item xs={4}>
                        <Grid container direction="column" item>
                            <Button variant={"contained"} disabled={detailStatus} onClick={InstallatiesToevoegen}>Installatie Toevoegen</Button>
                            <Button variant={"contained"} disabled={!detailStatus}  onClick={InstallatiesBewerken}>Installatie Bewerken</Button>
                            <Button variant={"contained"} disabled={!detailStatus}  onClick={InstallatiesVerwijderen}>Installatie Verwijderen</Button>
                        </Grid>
                    </Grid>

                </Grid>

            </Grid>
        </Grid>
    </>)
}

export default InstallatiesBlock