//<editor-fold desc="Retrieval">
import {
    AuthToken,
    baseUrl,
    ComplianceScheduleUrl,
    PoolUrl,
    REST
} from "./constants";

export function getPoolRecord(Identifier: any)
{
    const requestOptions = {
        method: 'GET',
        headers: {
            Authorization: 'Bearer ' + AuthToken
        }
    };

    return fetch(baseUrl + REST + PoolUrl + Identifier, requestOptions).then()

}

//</editor-fold>
//<editor-fold desc="Update">

export function submitObject(details: any, AggregateId: string, Command: string)
{
    const requestOptions = {
        method: 'POST',
        headers: {
            Authorization: 'Bearer ' + AuthToken
        },
        body: JSON.stringify(details)
    };
    return fetch(baseUrl + REST + PoolUrl + AggregateId + Command, requestOptions)
        .then(response => response)
}

export function submitUpdateComplianceObject(details: any, AggregateId: string, Command: string)
{
    const requestOptions = {
        method: 'POST',
        headers: {
            Authorization: 'Bearer ' + AuthToken
        },
        body: JSON.stringify(details)
    };
    return fetch(baseUrl + REST + ComplianceScheduleUrl + "/" + AggregateId + Command, requestOptions)
        .then(response => response)
}

export function submitNewComplianceObject(details: any, Command: string)
{
    const requestOptions = {
        method: 'POST',
        headers: {
            Authorization: 'Bearer ' + AuthToken
        },
        body: JSON.stringify(details)
    };
    return fetch(baseUrl + REST + ComplianceScheduleUrl + Command, requestOptions)
        .then(response => response)
}

//</editor-fold>
